import React from "react"
import { BsLinkedin } from "react-icons/bs"
import { FaGithub } from "react-icons/fa"

function HeaderSocials() {
  return (
    <div className="header_socials">
      <a href="https://github.com/LaneAntunes" target="_blank">
        <FaGithub />
      </a>
    </div>
  )
}

export default HeaderSocials
